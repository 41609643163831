import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import '../plugins/firebase';
import firebase from 'firebase/compat/app';

// Auth pages
const SignIn = () => import("../views/Auth/SignIn.vue");

// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const HospitalInfo = () => import("../views/Dashboard/HospitalInfo.vue");
const HospitalUsers = () => import("../views/Dashboard/HospitalUsers.vue");
const HospitalSettle = () => import("../views/Dashboard/HospitalSettle.vue");
const HospitalPlans = () => import("../views/Dashboard/HospitalPlans.vue");
const ShortUrls = () => import("../views/Dashboard/ShortUrls.vue");
const PharmacyInfo = () => import("../views/Dashboard/PharmacyInfo.vue");
const PharmacyUsers = () => import("../views/Dashboard/PharmacyUsers.vue");
const PharmacySettle = () => import("../views/Dashboard/PharmacySettle.vue");
const Questions = () => import("../views/Dashboard/Questions.vue");
const Notices = () => import("../views/Dashboard/Notices.vue");
const Manuals = () => import("../views/Dashboard/Manuals.vue");
const MyInfo = () => import("../views/Dashboard/MyInfo.vue");
const UserInfo = () => import("../views/Dashboard/UserInfo.vue");
const AppUsers = () => import("../views/Dashboard/AppUsers.vue");
const Boards = () => import("../views/Dashboard/Boards.vue");
const Deliveries = () => import("../views/Dashboard/Deliveries.vue");
const Popups = () => import("../views/Dashboard/Popups.vue");
const PopupList = () => import("../views/Dashboard/PopupList.vue");
const Statistics = () => import("../views/Dashboard/Statistics.vue");
const Settings = () => import("../views/Dashboard/Settings.vue");
const CouponManager = () => import("../views/Dashboard/CouponManager.vue");
const SendNotification = () => import("../views/Dashboard/SendNotification.vue");
const Notifications = () => import("../views/Dashboard/Notifications.vue");
const HospitalPayments = () => import("../views/Dashboard/HospitalPayments.vue");
const PharmacyPayments = () => import("../views/Dashboard/PharmacyPayments.vue");
const Visitors = () => import("../views/Dashboard/Visitors.vue");
const CurrentVisitors = () => import("../views/Dashboard/CurrentVisitors.vue");
const DefaultSettings = () => import("../views/Dashboard/DefaultSettings.vue");
const HospitalPlanInfo = () => import("../views/Dashboard/HospitalPlanInfo.vue");
const HospitalNotifications = () => import("../views/Dashboard/HospitalNotifications.vue");
const HospitalClinics = () => import("../views/Dashboard/HospitalClinics.vue");
const HistoryInfo = () => import("../views/Dashboard/HistoryInfo.vue");
const PharmacyWorks = () => import("../views/Dashboard/PharmacyWorks.vue");
const PharmacyWorkInfo = () => import("../views/Dashboard/PharmacyWorkInfo.vue");
const NonBenefits = () => import("../views/Dashboard/NonBenefits.vue");
const SellerUsers = () => import("../views/Dashboard/SellerUsers.vue");
const PartnersUsers = () => import("../views/Dashboard/PartnersUsers.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    meta: {
      requireAuth: true
    },
    name: "Dashboard",
    component: DashboardLayout,
    redirect: "/pages/dashboard/dashboard",
    props: true,
    children: [
      {
        path: "/pages/dashboard/dashboard",
        name: "홈화면",
        props: true,
        component: Dashboard,
      }, {
        path: "/pages/dashboard/users",
        name: "회원관리",
        component: UserInfo,
        meta: {
          name: "회원관리",
          groupName: "관리자",
        },
        props: true,
        children: [
          {
            path: "/pages/dashboard/users/app-users",
            name: "일반회원",
            component: AppUsers,
            meta: {
              name: "일반회원",
              groupName: "관리자",
              secondGroupName: "회원관리",
            },
            props: true,
          }, {
            path: "/pages/dashboard/users/hospital-users",
            name: "병원회원",
            component: HospitalUsers,
            meta: {
              name: "병원회원",
              groupName: "관리자",
              secondGroupName: "회원관리",
            },
            props: true,
          },
          {
            path: "/pages/dashboard/users/pharmacy-users",
            name: "약국회원",
            component: PharmacyUsers,
            meta: {
              name: "약국회원",
              groupName: "관리자",
              secondGroupName: "회원관리",
            },
            props: true,
          },
          {
            path: "/pages/seller-users",
            name: "판매자회원",
            component: SellerUsers,
            meta: {
              name: "판매자회원",
              groupName: "관리자",
              secondGroupName: "회원관리",
            },
            props: true,
          },
          {
            path: "/pages/partners-users",
            name: "파트너스회원",
            component: PartnersUsers,
            meta: {
              name: "파트너스회원",
              groupName: "관리자",
              secondGroupName: "회원관리",
            },
            props: true,
          },
          {
            path: "/pages/dashboard/users/send-notification",
            name: "푸시발송",
            component: SendNotification,
            meta: {
              name: "푸시발송",
              groupName: "관리자",
              secondGroupName: "회원관리",
            },
            props: true,
          },
          {
            path: "/pages/dashboard/users/notifications",
            name: "푸시발송리스트",
            component: Notifications,
            meta: {
              name: "푸시발송리스트",
              groupName: "관리자",
              secondGroupName: "회원관리",
            },
            props: true,
          },
        ],
      }, {
        path: "/pages/dashboard/deliveries",
        name: "배송관리",
        component: Deliveries,
        meta: {
          name: "배송관리",
          groupName: "관리자",
        },
        props: true,
      }, {
        path: "/pages/dashboard/boards",
        name: "게시판관리",
        component: Boards,
        meta: {
          name: "게시판관리",
          groupName: "관리자",
        },
        props: true,
        children: [
          {
            path: "/pages/dashboard/boards/questions",
            name: "고객문의",
            component: Questions,
            props: true,
            meta: {
              name: "고객문의",
              groupName: "관리자",
              secondGroupName: "게시판관리",
            },
          }, {
            path: "/pages/dashboard/boards/notices",
            name: "공지사항",
            component: Notices,
            meta: {
              name: "공지사항",
              groupName: "관리자",
              secondGroupName: "게시판관리",
            },
            props: true,
          }, {
            path: "/pages/dashboard/boards/manuals",
            name: "사용방법",
            component: Manuals,
            meta: {
              name: "사용방법",
              groupName: "관리자",
              secondGroupName: "게시판관리",
            },
            props: true,
          },
        ],
      }, {
        path: "/pages/dashboard/hospitals",
        name: "병원관리",
        component: HospitalInfo,
        meta: {
          name: "병원관리",
          groupName: "관리자",
        },
        props: true,
        children: [
          {
            path: "/pages/dashboard/hospitals/hospital-payments",
            name: "병원 결제내역",
            component: HospitalPayments,
            meta: {
              name: "병원 결제내역",
              groupName: "관리자",
              secondGroupName: "병원관리",
            },
            props: true,
          }, {
            path: "/pages/dashboard/hospitals/hospital-settle",
            name: "병원별 정산내역",
            component: HospitalSettle,
            meta: {
              name: "병원별 정산내역",
              groupName: "관리자",
              secondGroupName: "병원관리",
            },
            props: true,
          },
          {
            path: "/pages/dashboard/hospitals/plans",
            name: "병원별 플랜관리",
            component: HospitalPlanInfo,
            meta: {
              name: "병원별 플랜관리",
              groupName: "관리자",
              secondGroupName: "병원관리",
            },
            props: true,
          },
          {
            path: "/pages/dashboard/hospitals/coupon-manager",
            name: "쿠폰관리자",
            component: CouponManager,
            meta: {
              name: "쿠폰관리자",
              groupName: "관리자",
              secondGroupName: "병원관리",
            },
            props: true,
          },
          {
            path: "/pages/dashboard/hospitals/hospital-notifications",
            name: "병원푸시관리",
            component: HospitalNotifications,
            meta: {
              name: "병원푸시관리",
              groupName: "관리자",
              secondGroupName: "병원관리",
            },
            props: true,
          },
          {
            path: "/pages/dashboard/hospitals/hospital-clinics",
            name: "진료목록",
            component: HospitalClinics,
            meta: {
              name: "진료목록",
              groupName: "관리자",
              secondGroupName: "병원관리",
            },
            props: true,
          },
          {
            path: "/pages/dashboard/hospitals/hospital-clinic-view",
            name: "진료정보",
            component: HistoryInfo,
            meta: {
              name: "진료정보",
              groupName: "관리자",
              secondGroupName: "병원관리",
            },
            props: true,
          },
          {
            path: "/pages/dashboard/hospitals/non-benefits",
            name: "비급여 항목 관리",
            component: NonBenefits,
            meta: {
              name: "비급여 항목 관리",
              groupName: "관리자",
              secondGroupName: "병원관리",
            },
            props: true,
          },
        ],
      }, {
        path: "/pages/dashboard/pharmacys",
        name: "약국관리",
        component: PharmacyInfo,
        meta: {
          name: "약국관리",
          groupName: "관리자",
        },
        props: true,
        children: [
          {
            path: "/pages/dashboard/pharmacys/pharmacy-payments",
            name: "약국 결제내역",
            component: PharmacyPayments,
            meta: {
              name: "약국 결제내역",
              groupName: "관리자",
              secondGroupName: "약국관리",
            },
            props: true,
          }, {
            path: "/pages/dashboard/pharmacys/pharmacy-settle",
            name: "약국별 정산내역",
            component: PharmacySettle,
            meta: {
              name: "약국별 정산내역",
              groupName: "관리자",
              secondGroupName: "약국관리",
            },
            props: true,
          }, {
            path: "/pages/dashboard/pharmacys/pharmacy-works",
            name: "조제관리",
            component: PharmacyWorks,
            meta: {
              name: "조제관리",
              groupName: "관리자",
              secondGroupName: "약국관리",
            },
            props: true,
          }, {
            path: "/pages/dashboard/pharmacys/pharmacy-work-info",
            name: "조제정보",
            component: PharmacyWorkInfo,
            meta: {
              name: "조제정보",
              groupName: "관리자",
              secondGroupName: "약국관리",
            },
            props: true,
          },
        ],
      }, {
        path: "/pages/dashboard/popups",
        name: "팝업관리",
        component: Popups,
        meta: {
          name: "팝업관리",
          groupName: "관리자",
        },
        props: true,
        children: [
          {
            path: "/pages/dashboard/popups/popup-list",
            name: "팝업 리스트",
            component: PopupList,
            meta: {
              name: "팝업 리스트",
              groupName: "관리자",
              secondGroupName: "팝업관리",
            },
            props: true,
          }
        ],
      }, {
        path: "/pages/dashboard/statistics",
        name: "통계",
        component: Statistics,
        meta: {
          name: "통계",
          groupName: "관리자",
        },
        props: true,
        children: [
          {
            path: "/pages/dashboard/statistics/visitors",
            name: "진료 통계",
            component: Visitors,
            meta: {
              name: "진료 통계",
              groupName: "관리자",
              secondGroupName: "통계",
            },
            props: true,
          },
          {
            path: "/pages/dashboard/statistics/current-visitors",
            name: "현재접속자현황",
            component: CurrentVisitors,
            meta: {
              name: "현재접속자현황",
              groupName: "관리자",
              secondGroupName: "통계",
            },
            props: true,
          }
        ],
      }, {
        path: "/pages/dashboard/settings",
        name: "설정",
        component: Settings,
        meta: {
          name: "설정",
          groupName: "관리자",
        },
        props: true,
        children: [
          {
            path: "/pages/dashboard/settings/default-settings",
            name: "기본설정",
            component: DefaultSettings,
            meta: {
              name: "기본설정",
              groupName: "관리자",
              secondGroupName: "설정",
            },
            props: true,
          },
          {
            path: "/pages/dashboard/settings/hospital-plans",
            name: "플랜관리",
            component: HospitalPlans,
            meta: {
              name: "플랜관리",
              groupName: "관리자",
              secondGroupName: "설정",
            },
            props: true,
          },
          {
            path: "/pages/dashboard/settings/short-urls",
            name: "단축URL관리",
            component: ShortUrls,
            meta: {
              name: "단축URL관리",
              groupName: "관리자",
              secondGroupName: "설정",
            },
            props: true,
          }
        ],
      },
      {
        path: "/pages/dashboard/my-info",
        name: "비밀번호 변경",
        component: MyInfo,
        meta: {
          name: "비밀번호 변경",
          groupName: "기타",
        },
      },
    ],
  },
  {
    path: '/',
    name: "Auth",
    redirect: "/pages/auth/signin",
    component: AuthBasicLayout,
    children: [
      {
        path: "/pages/auth/signin",
        name: "로그인",
        component: SignIn,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

var unsubscribe = null;

router.beforeEach((to, from, next) => {
  if (unsubscribe == null) {
    unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
      if (user == null) {
        next({ path: '/pages/auth/signin' });
      }
    });
  }

  if (to.matched.some(record => record.meta.requireAuth)) {
    let user = firebase.auth().currentUser;
    if (user == null) {
      next({ path: '/pages/auth/signin' });
    }
    else {
      if ((user.email != null) && (user.email != undefined) && (user.email.includes('@doctor365admin.app'))) {
        next();
      }
      else {
        next({ path: '/pages/auth/signin' });
      }
    }
  }
  else {
    next();
  }
});

export default router;
