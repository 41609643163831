import Vue from "vue";
import firebase from 'firebase/compat/app';

export default {
};

export const realtime = {
    install(Vue) {
        Vue.prototype.$realtime = {
            database: firebase.database(),
            timestamp: firebase.database.ServerValue.TIMESTAMP,
        }
    }
};

Vue.use(realtime);