import Vue from "vue";
import axios from "axios";

export default {
};

export const gorela = {
  install(Vue) {
    Vue.prototype.$gorela = {
      companyId: "doctor365",
      storeId: "doctor365p",
      authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZVc2VySWQiOjE0NCwiYXBpS2V5SWQiOjEwMDEsInJvbGUiOiJvcmRlckFnZW5jeU1hbmFnZXIiLCJvcmRlckFnZW5jeUlkcyI6WyJkb2N0b3IzNjUiXSwiaWF0IjoxNjYwMDMwNjA4LCJleHAiOjE4MTc3MTA2MDh9.5N2igxPCgOXUTZFRdSpuQ5rX6qHvGMr7gU4YfT1bkMs",
      requestDeliveryPossible: async function (item, price) {
        let result = {};
        var url = 'https://staging-api-interlocker.gorelas.com/api/delivery-possible';
        var data = {
          "orderAgencyId": this.companyId,
          "orderAgencyStoreId": this.storeId,
          "dropJibunAddress": item.address,
          "dropAddressDetail": item.addressDetail,
          "pickupWishAt": (new Date).getTime(),
          "totalPayPrice": price,
          "actualPayPrice": price,
        };
        if ((item.doroAddress != null) && (item.doroAddress != undefined)) {
          if (item.doroAddress.length > 0) {
            data['dropRoadAddress'] = item.doroAddress;
          }
        }
        console.log('data', data);
        await axios.post(url, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            "Authorization": this.authorization
          },
        })
          .then(function (response) {
            result = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
        return result;
      },
      requestOrder: async function (item, orderId) {
        let result = {};
        var url = 'https://staging-api-interlocker.gorelas.com/api/orders';
        var data = {
          "orderType": "FOR_DELIVERY",
          "orderAgencyId": this.companyId,
          "orderAgencyOrderId": orderId,
          "orderAgencyStoreId": this.storeId,
          "totalPayPrice": item.paymentAmount,
          "actualPayPrice": item.paymentAmount,
          "prepaidPrice": item.paymentAmount,
          "deferredPrice": 0,
          "paymentCashPrice": 0,
          "paymentCardPrice": 0,
          "orderProducts": [{
            "type": "PHARMACY",
            "name": "의약품",
            "totalPrice": item.paymentAmount,
            "unitPrice": item.paymentAmount,
            "quantity": 1,
          }],
          "ordererPhone": item.patientPhone,
          "receiverPhone": item.patientPhone,
          "ordererName": item.patientName,
          "receiverName": item.patientName,
          "ordererOrderMemo": item.ordererOrderMemo, // model에 추가하고 앱에서 입력받도록 해야함
          "pickupWishAt": (new Date()).getTime(),
          "dropJibunAddress": item.address, // 도로명주소가 아닌 법정동이 들어가야 함 -> 앱에서 법정동으로 저장되도록 해야함
          "dropAddressDetail": item.addressDetail
        };
        if ((item.addressAdditionalInfo != undefined) && (item.addressAdditionalInfo != null)) {
          if (item.addressAdditionalInfo.length > 0) {
            data.ordererOrderMemo = item.addressAdditionalInfo;
          }
        }
        await axios.post(url, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            "Authorization": this.authorization
          },
        })
          .then(function (response) {
            result = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
        return result;
      },
      getOrders: async function (offset, limit) {
        let result = {};
        var url = 'https://staging-api-interlocker.gorelas.com/api/orders';
        url += '?_offset=' + offset + '&_limit=' + limit + '&_sortBy=createdAt&_sortType=DESC';
        await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": this.authorization
          },
        })
          .then(function (response) {
            result = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
        return result;
      },
      getOrder: async function (orderId) {
        let result = null;
        var url = 'https://staging-api-interlocker.gorelas.com/api/orders/' + orderId;
        await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": this.authorization
          },
        })
          .then(function (response) {
            result = response.data.data;
            console.log(result);
          })
          .catch(function (error) {
            console.log(error);
          });
        return result;
      }
    }
  }
};

Vue.use(gorela);