// [AdminNotice]
// String id;
// DateTime createAt;
// String title;
// String content;
// String adminNoticeTarget;
// List<dynamic> questionAttachmentUrls;
// List<dynamic> imageUrls;
// List<dynamic> docUrls;
// List<dynamic> movieUrls;
// List<dynamic> youtubeUrls;

// [Qna]
// String id;
// DateTime createAt;
// QuestionerType questionerType;
// any appUserId;
// any appUserName;
// any hospitalId;
// any hospitalName;
// any hospitalWorkerId;
// any hospitalWorkerName;
// String pharmacyId;
// String pharmacyName;
// any pharmacyWorkerId;
// any pharmacyWorkerName;
// String category;
// String title;
// String content;
// List<dynamic> questionAttachmentUrls;
// bool isReplied;
// String reply;
// DateTime replyAt;
// List<dynamic> replyImageUrls;
// List<dynamic> replyDocUrls;

// [Hospital]
// String id;
// DateTime createAt;
// String hospitalCode;
// String hospitalCodeUrl;
// String hospitalCodeQrUrl;
// List<dynamic> tagList;
// int numOfFavorites;
// String name;
// bool availableVideoCall;
// bool availableAudioCall;
// String address;
// String addressDetail;
// double gpsX;
// double gpsY;
// String phone;
// String fax;
// bool mondayEnable;
// bool tuesdayEnable;
// bool wednesdayEnable;
// bool thursdayEnable;
// bool fridayEnable;
// bool saturdayEnable;
// bool sundayEnable;
// DateTime mondayOpenAt;
// DateTime tuesdayOpenAt;
// DateTime wednesdayOpenAt;
// DateTime thursdayOpenAt;
// DateTime fridayOpenAt;
// DateTime saturdayOpenAt;
// DateTime sundayOpenAt;
// DateTime mondayCloseAt;
// DateTime tuesdayCloseAt;
// DateTime wednesdayCloseAt;
// DateTime thursdayCloseAt;
// DateTime fridayCloseAt;
// DateTime saturdayCloseAt;
// DateTime sundayCloseAt;
// bool availableAtHoliday;
// List<dynamic> hospitalImages;
// List<dynamic> availableMedicalSubjects;
// String availableMedicalSubject;
// String businessLicenseFileUrl;
// String accountBankName;
// String accountNumber;
// String accountOwnerName;
// String invoiceEmail;
// any subscriptionPlan;
// String subscriptionCardType;
// String subscriptionCardCompany;
// String subscriptionCardOwnerName;
// String subscriptionCardNumber;
// String subscriptionCardValidDate;
// String subscriptionCardCvc;
// bool isReservationAllTime;
// DateTime reservationOpenAt;
// DateTime reservationCloseAt;
// DateTime reservationOpenAt2;
// DateTime reservationCloseAt2;
// int reservationInvervalMinutes;
// List<dynamic> reservationAvailableDoctorIds;
// String settlementPersonName;
// String settlementPersonPhone;
// String businessNumber;
// DateTime mondayLunchStartAt;
// DateTime tuesdayLunchStartAt;
// DateTime wednesdayLunchStartAt;
// DateTime thursdayLunchStartAt;
// DateTime fridayLunchStartAt;
// DateTime saturdayLunchStartAt;
// DateTime sundayLunchStartAt;
// DateTime mondayLunchEndAt;
// DateTime tuesdayLunchEndAt;
// DateTime wednesdayLunchEndAt;
// DateTime thursdayLunchEndAt;
// DateTime fridayLunchEndAt;
// DateTime saturdayLunchEndAt;
// DateTime sundayLunchEndAt;
// String postCode;
// String phone1;
// String phone2;
// String phone3;
// String fax1;
// String fax2;
// String fax3;
// String settlementPersonPhone1;
// String settlementPersonPhone2;
// String settlementPersonPhone3;
// bool mondayLunch;
// bool tuesdayLunch;
// bool wednesdayLunch;
// bool thursdayLunch;
// bool fridayLunch;
// bool saturdayLunch;
// bool sundayLunch;
// any operatingTimes;
// any holidayWeekDays;
// bool quit;
// any whyQuit;
// DateTime quitDate;
// DateTime planUntilDate;
// String approveState;
// String hospitalState;
// DateTime subscriptionStartAt;
// int subscriptionNumOfAdditionalPeople;
// bool isAddressJibun;
// bool subscriptionPayment;
// String hospitalNumber;
// bool enableAutoPay;
// bool enableAutoAccept;
// int nextClinicNotificationDeadline;
// bool minMilageSubscription;
// int autoPurchaseMileages;
// int minMileages;
// int pushMileages;
// String billingKey;
// String cardName;
// String cardNumber;
// String hospitalType;
// String signUpCode;
// String signUpCodePhone;
// String signUpCodeChannel;
// String whenToPay;
// String whenToPayBase;
// int whenToPayDay;
// List<String> quotationPaymentMethods;

// [HospitalWork]
// String id;
// DateTime createAt;
// DateTime reservationDateTime;
// DateTime callStartTime;
// DateTime callEndTime;
// ClinicState clinicState;
// String patientId;
// String patientName;
// String patientAddress;
// String patientPhone;
// String patientResidentNumber;
// String patientGender;
// DateTime patientBirth;
// String medicationInUse;
// String allergy;
// any diseases;
// bool isSecondTimeClinic;
// bool isVideoClinic;
// String symptomDescription;
// List<dynamic> images;
// List<dynamic> requestCertificates;
// String hospitalId;
// String hospitalName;
// String doctorId;
// String doctorName;
// String patientUserMemo;
// int waitingTimeMinutes;
// String doctorMemo;
// int priceBenefit;
// int priceNonBenefit;
// int priceDelivery;
// String prescriptionFileUrl;
// String prescriptionBriefDescription;
// bool isNextScheduleRequired;
// int nextScheduleAfter;
// String nextScheduleDurationUnit;
// int nextClinicDate;
// String nextScheduleDescription;
// String videoRecordFileUrl;
// String audioRecordFileUrl;
// DateTime payAt;
// String pharmacyId;
// String pharmacyWorkId;
// String pharmacyName;
// bool isSettled;
// DateTime settledAt;
// int settleAmount;
// int paymentAmount;
// any paymentResult;

// [Pharmacy]
// String id;
// DateTime createAt;
// String accountId;
// String accountUserName;
// DateTime accountUserBirth;
// String accountUserPhone;
// String accountUserEmail;
// String pharmacistLicenseImageUrl;
// int numOfFavorites;
// String name;
// String address;
// String addressDetail;
// double gpsX;
// double gpsY;
// String phone;
// String fax;
// bool mondayEnable;
// bool tuesdayEnable;
// bool wednesdayEnable;
// bool thursdayEnable;
// bool fridayEnable;
// bool saturdayEnable;
// bool sundayEnable;
// DateTime mondayOpenAt;
// DateTime tuesdayOpenAt;
// DateTime wednesdayOpenAt;
// DateTime thursdayOpenAt;
// DateTime fridayOpenAt;
// DateTime saturdayOpenAt;
// DateTime sundayOpenAt;
// DateTime mondayCloseAt;
// DateTime tuesdayCloseAt;
// DateTime wednesdayCloseAt;
// DateTime thursdayCloseAt;
// DateTime fridayCloseAt;
// DateTime saturdayCloseAt;
// DateTime sundayCloseAt;
// bool availableAtHoliday;
// List<dynamic> pharmacyImages;
// String businessLicenseFileUrl;
// String accountBankName;
// String accountNumber;
// String accountOwnerName;
// String invoiceEmail;
// List<dynamic> tagList;
// String businessNumber;
// String settlementPersonName;
// String settlementPersonPhone;
// DateTime mondayLunchStartAt;
// DateTime tuesdayLunchStartAt;
// DateTime wednesdayLunchStartAt;
// DateTime thursdayLunchStartAt;
// DateTime fridayLunchStartAt;
// DateTime saturdayLunchStartAt;
// DateTime sundayLunchStartAt;
// DateTime mondayLunchEndAt;
// DateTime tuesdayLunchEndAt;
// DateTime wednesdayLunchEndAt;
// DateTime thursdayLunchEndAt;
// DateTime fridayLunchEndAt;
// DateTime saturdayLunchEndAt;
// DateTime sundayLunchEndAt;
// List<dynamic> operatingTimes;
// bool mondayLunch;
// bool tuesdayLunch;
// bool wednesdayLunch;
// bool thursdayLunch;
// bool fridayLunch;
// bool saturdayLunch;
// bool sundayLunch;
// String postCode;
// List<dynamic> holidayWeekDays;
// String phone1;
// String phone2;
// String phone3;
// String fax1;
// String fax2;
// String fax3;
// String settlementPersonPhone1;
// String settlementPersonPhone2;
// String settlementPersonPhone3;
// String accountUserPhone1;
// String accountUserPhone2;
// String accountUserPhone3;
// bool quit;
// any whyQuit;
// String approveState;
// String pharmacyState;
// bool kakaoTalkIdNotification;
// String kakaoTalkId;
// bool shopOpened;
// bool isAddressJibun;
// String gender;
// String pharmacyNumber;
// bool enableInDay;
// bool enableQuick;
// bool enableMail;

// [PharmacyWork]
// String id;
// DateTime createAt;
// any guideSaved;
// any priceSaved;
// String whyCancel;
// DeliveryState deliveryState;
// DeliveryType deliveryType;
// String address;
// String addressDetail;
// String addressAdditionalInfo;
// any requestPharmacyProducts;
// String patientId;
// String patientName;
// String patientGender;
// DateTime patientBirth;
// String patientPhone;
// String prescriptionFileUrl;
// int paymentAmount;
// int settleAmount;
// DateTime payAt;
// String receiptImageUrl;
// String hospitalId;
// String hospitalWorkId;
// String hospitalName;
// String doctorId;
// String doctorName;
// String pharmacyId;
// String pharmacyName;
// String pharmacyWorkerName;
// String pharmacyAddress;
// String patientUserMemo;
// String name;
// String guide;
// bool morning;
// bool lunch;
// bool dinner;
// bool night;
// TakeMedicineTime takeMedicineTime;
// int takeMedicineTimeAfterOrBefore;
// int timesPerDay;
// int numOfdays;
// int priceMedicine;
// int priceEtc;
// int priceDelivery;
// DateTime makingMedicineAt;
// String pharmacyMemo;
// String trackingNumber;
// String deliveryPersonPhone;
// bool isSettled;
// DateTime settledAt;
// String pharmacistId;
// bool deliveryCancel;
// String deliveryCancelReason;
// String deliveryCancelType;
// any paymentResult;

// [PlanFeature]
// String title;
// String text;
// String description;

// [User]
// String id;
// DateTime createAt;
// DateTime passwordChangeAt;
// String recentLoginVersion;
// DateTime recentLoginAt;
// String email;
// String name;
// String residentNumber;
// String phone;
// DateTime birth;
// String gender;
// String recommendHospitalCode;
// String recommendHospitalId;
// String medicationInUse;
// String address;
// String doroAddress;
// String addressDetail;
// String addressPostCode;
// String allergy;
// any diseases;
// String profileImageUrl;
// String profileImageBlurHash;
// UserType userType;
// MembershipType membershipType;
// SignUpMethod signUpMethod;
// int point;
// String myInvitationLink;
// bool allowMarketing;
// bool allowServicePush;
// String pushToken;
// String currentHospitalId;
// List<dynamic> favoriteHospitals;
// List<dynamic> favoritePharmacys;
// DateTime hasSeenNotificationTime;
// List<dynamic> recentPharmacyIds;
// String myMainCardId;
// String myMainCardBillingKey;

// [Coupon]
// String id;
// DateTime createAt;
// String type;
// int duration;
// String number;
// int count;
// int validity;
// DateTime expirationFrom;
// DateTime expirationTo;

// [CouponUsage]
// String id;
// DateTime createAt;
// String userType;
// String userId;
// String userName;
// DateTime startAt;
// DateTime endAt;
// String couponId;

// [HospitalSubscriptionPlan]
// String id;
// String title;
// int price;
// List<dynamic> features;
// int paymentDurationMonth;
// int clinicTimeLimit;
// bool clinicTimeUnlimited;
// int pushLimit;
// bool pushLimitUnlimited;
// int storage;
// bool storageUnlimited;
// int index;
// String benefit;
// String description;
// double feeRate;
// bool active;
// int trialDurationMonth;
// int pricePerItem;
// bool noPricePerItem;

// [Popup]
// String id;
// DateTime createAt;
// String name;
// String linkTarget;
// String imageUrl;
// String link;
// DateTime showStartDateTime;
// DateTime showEndDateTime;
// int positionTop;
// int positionLeft;
// int width;
// int height;
// bool active;
// List<dynamic> sites;

// [AdminNotification]
// String id;
// DateTime createAt;
// DateTime reservedAt;
// bool sended;
// DateTime sendedAt;
// String title;
// String content;
// String link;
// List<dynamic> target;
// List<dynamic> receivedAppUserIds;
// List<dynamic> receivedHospitalUserIds;
// List<dynamic> receivedPharmacyUserIds;

// [HospitalWorker]
// String id;
// DateTime createAt;
// String name;
// String accountId;
// HospitalWorkerType hospitalWorkerType;
// String title;
// bool enable;
// String hospitalId;
// String hospitalName;
// List<dynamic> specialistLicenseFileUrls;
// String doctorLicenseImageUrl;
// bool mondayEnable;
// bool tuesdayEnable;
// bool wednesdayEnable;
// bool thursdayEnable;
// bool fridayEnable;
// bool saturdayEnable;
// bool sundayEnable;
// bool hasLunchTime;
// DateTime lunchStartTime;
// DateTime lunchcEndTime;
// DateTime mondayOpenAt;
// DateTime tuesdayOpenAt;
// DateTime wednesdayOpenAt;
// DateTime thursdayOpenAt;
// DateTime fridayOpenAt;
// DateTime saturdayOpenAt;
// DateTime sundayOpenAt;
// DateTime mondayCloseAt;
// DateTime tuesdayCloseAt;
// DateTime wednesdayCloseAt;
// DateTime thursdayCloseAt;
// DateTime fridayCloseAt;
// DateTime saturdayCloseAt;
// DateTime sundayCloseAt;
// bool isSameWithHospitalHoliday;
// String faceImageUrl;
// List<dynamic> tagList;
// bool isMale;
// String birth;
// String phone;
// String phone1;
// String phone2;
// String phone3;
// String email;
// String job;
// String team;
// any operatingTimes;
// bool mondayLunch;
// bool tuesdayLunch;
// bool wednesdayLunch;
// bool thursdayLunch;
// bool fridayLunch;
// bool saturdayLunch;
// bool sundayLunch;
// any holidayWeekDays;
// bool main;
// String memo;
// String hospitalSubscriptionId;
// int step;
// int reminderInvervalMinutes;
// bool availableAtHoliday;
// bool availableNormalClinic;
// bool availableOnlineClinic;
// bool availableVideoCall;
// bool availableAudioCall;
// int reminderLimit;
// bool reminderAllTime;
// DateTime reminderStartTime;
// DateTime reminderEndTime;
// String gender;
// bool showAllClinic;

// [Pharmacist]
// String id;
// DateTime createAt;
// String name;
// String pharmacistLicenseImageUrl;
// String profileImageUrl;

// [NonBenefit]
// String id;
// DateTime createAt;
// String type;
// String name;
// int price;
// bool isAttached;
// String fileUrl;
// int orderPriority;
// List<dynamic> descriptionFileUrlList;
// List<dynamic> descriptionImageUrlList;
// String descriptionText;
// bool hide;
// List<dynamic> doctorIds;
// List<dynamic> selections;

// [HospitalNotice]
// String id;
// String hospitalId;
// String authorId;
// String authorName;
// String authorTitle;
// DateTime createAt;
// String header;
// bool isMainNotice;
// String title;
// String content;
// List<dynamic> attachmentUrls;
// List<dynamic> imageUrls;
// List<dynamic> docUrls;
// List<dynamic> movieUrls;
// List<dynamic> youtubeUrls;

// [TempHoliday]
// String id;
// DateTime createAt;
// String hospitalId;
// String pharmacyId;
// DateTime date;
// String name;

// [RelatedPharmacy]
// String id;
// DateTime createAt;
// String pharmacyId;
// String name;
// String phone;
// String address;
// String addressDetail;
// int order;

// [Document]
// String id;
// DateTime createAt;
// String title;
// List<dynamic> fileUrls;
// String hospitalWorkId;
// String hospitalName;
// DocumentType documentType;
// bool download;

// [Manual]
// String id;
// DateTime createAt;
// String type;
// String title;
// String content;
// List<dynamic> imageUrls;
// List<dynamic> docUrls;
// List<dynamic> movieUrls;
// List<dynamic> youtubeUrls;

// [NonBenefitOption]
// String id;
// String name;
// String type;
// int price;
// String descriptionText;
// String hospitalId;

// [NonBenefitSelection]
// String id;
// DateTime createAt;
// List<dynamic> optionIds;
// List<dynamic> options;
// int requiredCount;

// [ReservationClose]
// String id;
// DateTime createAt;
// String hospitalId;
// String doctorId;
// DateTime time;

// [SellerUser]
// String id;
// DateTime createAt;
// String name;
// String phone;
// String address;
// String addressDetail;
// String businessNumber;
// String ownerName;
// String ownerPhone;
// String ownerEmail;
// String sellerOwnerName;
// String sellerOwnerPhone;
// String businessCertificateUrl;
// String taxEmail;
// List<dynamic> categorys;
// bool active;
// String accountType;
// String bankName;
// String accountNumber;
// String settleBankName;
// String settleAccountNumber;
// String settleAccountUser;
// String whenToPay;
// bool manualPayment;
// String postCode;

// [SignUpCodeUser]
// String id;
// DateTime createAt;
// String name;
// String phone;
// String bankName;
// String accountNumber;
// String accountOwner;
// bool tax;
// String taxResidentNumber;
// String taxCompany;
// String taxBusinessNumber;
// String taxOwner;
// String taxUptae;
// String taxJongmok;
// String taxAddress;
// String taxEmail;
// String taxManager;
// String taxPhone;

// [UserPointUsage]
// String id;
// DateTime createAt;
// String userId;
// String userName;
// String hospitalId;
// String hospitalName;
// int amount;
// String usageType;
// String hospitalWorkerId;
// String hospitalWorkerName;
// List<dynamic> paymentMethods;
// String paymentId;
// int paymentAmount;
// int serviceAmount;
// int resultAmount;
// String description;
// String memo;
// String hospitalWorkId;
// bool refunded;

// [UserPoint]
// String id;
// DateTime createAt;
// String userId;
// String hospitalId;
// int amount;

// [SignUpCodeChannel]
// String id;
// DateTime createAt;
// String name;
// int viewCount;
// String signUpCodeUserId;
// String url;
// String qrUrl;
// String signUpCode;

// [PartnerPointUsage]
// String id;
// DateTime createAt;
// String usageType;
// String partnerId;
// String partnerName;
// String hospitalId;
// String hospitalName;
// String description;
// List<dynamic> months;
// String memo;
// int amount;
// DateTime transferDate;
// bool tax;


/* eslint-disable no-unused-vars */
import Vue from "vue";
export default {
};

function isNull(text) {
	if ((typeof text == "undefined") || (text == null)) {
		return true;
	}
	else {
		return false;
	}
}

function toNumber(text) {
	if(isNull(text)) {
		return null;
	}
	else {
		return text * 1;
	}
}

function toText(number) {
	if(isNull(number)) {
		return null;
	}
	else {
		return number + '';
	}
}

function toSafeValue(value) {
	if(isNull(value)) {
		return null;
	}
	else {
		return value;
	}
}

export const models = {
	install(Vue) {
		Vue.prototype.$models = {
			AdminNoticeModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						title: '',
						content: '',
						adminNoticeTarget: '',
						questionAttachmentUrls: null,
						imageUrls: null,
						docUrls: null,
						movieUrls: null,
						youtubeUrls: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						title: toText(model.title),
						content: toText(model.content),
						adminNoticeTarget: toText(model.adminNoticeTarget),
						questionAttachmentUrls: toSafeValue(model.questionAttachmentUrls),
						imageUrls: toSafeValue(model.imageUrls),
						docUrls: toSafeValue(model.docUrls),
						movieUrls: toSafeValue(model.movieUrls),
						youtubeUrls: toSafeValue(model.youtubeUrls),
					};
				},
			},

			QnaModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						questionerType: null,
						appUserId: null,
						appUserName: null,
						hospitalId: null,
						hospitalName: null,
						hospitalWorkerId: null,
						hospitalWorkerName: null,
						pharmacyId: '',
						pharmacyName: '',
						pharmacyWorkerId: null,
						pharmacyWorkerName: null,
						category: '',
						title: '',
						content: '',
						questionAttachmentUrls: null,
						isReplied: null,
						reply: '',
						replyAt: null,
						replyImageUrls: null,
						replyDocUrls: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						questionerType: toSafeValue(model.questionerType),
						appUserId: toSafeValue(model.appUserId),
						appUserName: toSafeValue(model.appUserName),
						hospitalId: toSafeValue(model.hospitalId),
						hospitalName: toSafeValue(model.hospitalName),
						hospitalWorkerId: toSafeValue(model.hospitalWorkerId),
						hospitalWorkerName: toSafeValue(model.hospitalWorkerName),
						pharmacyId: toText(model.pharmacyId),
						pharmacyName: toText(model.pharmacyName),
						pharmacyWorkerId: toSafeValue(model.pharmacyWorkerId),
						pharmacyWorkerName: toSafeValue(model.pharmacyWorkerName),
						category: toText(model.category),
						title: toText(model.title),
						content: toText(model.content),
						questionAttachmentUrls: toSafeValue(model.questionAttachmentUrls),
						isReplied: toSafeValue(model.isReplied),
						reply: toText(model.reply),
						replyAt: toSafeValue(model.replyAt),
						replyImageUrls: toSafeValue(model.replyImageUrls),
						replyDocUrls: toSafeValue(model.replyDocUrls),
					};
				},
			},

			HospitalModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						hospitalCode: '',
						hospitalCodeUrl: '',
						hospitalCodeQrUrl: '',
						tagList: null,
						numOfFavorites: 0,
						name: '',
						availableVideoCall: null,
						availableAudioCall: null,
						address: '',
						addressDetail: '',
						gpsX: 0,
						gpsY: 0,
						phone: '',
						fax: '',
						mondayEnable: null,
						tuesdayEnable: null,
						wednesdayEnable: null,
						thursdayEnable: null,
						fridayEnable: null,
						saturdayEnable: null,
						sundayEnable: null,
						mondayOpenAt: null,
						tuesdayOpenAt: null,
						wednesdayOpenAt: null,
						thursdayOpenAt: null,
						fridayOpenAt: null,
						saturdayOpenAt: null,
						sundayOpenAt: null,
						mondayCloseAt: null,
						tuesdayCloseAt: null,
						wednesdayCloseAt: null,
						thursdayCloseAt: null,
						fridayCloseAt: null,
						saturdayCloseAt: null,
						sundayCloseAt: null,
						availableAtHoliday: null,
						hospitalImages: null,
						availableMedicalSubjects: null,
						availableMedicalSubject: '',
						businessLicenseFileUrl: '',
						accountBankName: '',
						accountNumber: '',
						accountOwnerName: '',
						invoiceEmail: '',
						subscriptionPlan: null,
						subscriptionCardType: '',
						subscriptionCardCompany: '',
						subscriptionCardOwnerName: '',
						subscriptionCardNumber: '',
						subscriptionCardValidDate: '',
						subscriptionCardCvc: '',
						isReservationAllTime: null,
						reservationOpenAt: null,
						reservationCloseAt: null,
						reservationOpenAt2: null,
						reservationCloseAt2: null,
						reservationInvervalMinutes: 0,
						reservationAvailableDoctorIds: null,
						settlementPersonName: '',
						settlementPersonPhone: '',
						businessNumber: '',
						mondayLunchStartAt: null,
						tuesdayLunchStartAt: null,
						wednesdayLunchStartAt: null,
						thursdayLunchStartAt: null,
						fridayLunchStartAt: null,
						saturdayLunchStartAt: null,
						sundayLunchStartAt: null,
						mondayLunchEndAt: null,
						tuesdayLunchEndAt: null,
						wednesdayLunchEndAt: null,
						thursdayLunchEndAt: null,
						fridayLunchEndAt: null,
						saturdayLunchEndAt: null,
						sundayLunchEndAt: null,
						postCode: '',
						phone1: '',
						phone2: '',
						phone3: '',
						fax1: '',
						fax2: '',
						fax3: '',
						settlementPersonPhone1: '',
						settlementPersonPhone2: '',
						settlementPersonPhone3: '',
						mondayLunch: null,
						tuesdayLunch: null,
						wednesdayLunch: null,
						thursdayLunch: null,
						fridayLunch: null,
						saturdayLunch: null,
						sundayLunch: null,
						operatingTimes: null,
						holidayWeekDays: null,
						quit: null,
						whyQuit: null,
						quitDate: null,
						planUntilDate: null,
						approveState: '',
						hospitalState: '',
						subscriptionStartAt: null,
						subscriptionNumOfAdditionalPeople: 0,
						isAddressJibun: null,
						subscriptionPayment: null,
						hospitalNumber: '',
						enableAutoPay: null,
						enableAutoAccept: null,
						nextClinicNotificationDeadline: 0,
						minMilageSubscription: null,
						autoPurchaseMileages: 0,
						minMileages: 0,
						pushMileages: 0,
						billingKey: '',
						cardName: '',
						cardNumber: '',
						hospitalType: '',
						signUpCode: '',
						signUpCodePhone: '',
						signUpCodeChannel: '',
						whenToPay: '',
						whenToPayBase: '',
						whenToPayDay: 0,
						quotationPaymentMethods: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						hospitalCode: toText(model.hospitalCode),
						hospitalCodeUrl: toText(model.hospitalCodeUrl),
						hospitalCodeQrUrl: toText(model.hospitalCodeQrUrl),
						tagList: toSafeValue(model.tagList),
						numOfFavorites: toNumber(model.numOfFavorites),
						name: toText(model.name),
						availableVideoCall: toSafeValue(model.availableVideoCall),
						availableAudioCall: toSafeValue(model.availableAudioCall),
						address: toText(model.address),
						addressDetail: toText(model.addressDetail),
						gpsX: toNumber(model.gpsX),
						gpsY: toNumber(model.gpsY),
						phone: toText(model.phone),
						fax: toText(model.fax),
						mondayEnable: toSafeValue(model.mondayEnable),
						tuesdayEnable: toSafeValue(model.tuesdayEnable),
						wednesdayEnable: toSafeValue(model.wednesdayEnable),
						thursdayEnable: toSafeValue(model.thursdayEnable),
						fridayEnable: toSafeValue(model.fridayEnable),
						saturdayEnable: toSafeValue(model.saturdayEnable),
						sundayEnable: toSafeValue(model.sundayEnable),
						mondayOpenAt: toSafeValue(model.mondayOpenAt),
						tuesdayOpenAt: toSafeValue(model.tuesdayOpenAt),
						wednesdayOpenAt: toSafeValue(model.wednesdayOpenAt),
						thursdayOpenAt: toSafeValue(model.thursdayOpenAt),
						fridayOpenAt: toSafeValue(model.fridayOpenAt),
						saturdayOpenAt: toSafeValue(model.saturdayOpenAt),
						sundayOpenAt: toSafeValue(model.sundayOpenAt),
						mondayCloseAt: toSafeValue(model.mondayCloseAt),
						tuesdayCloseAt: toSafeValue(model.tuesdayCloseAt),
						wednesdayCloseAt: toSafeValue(model.wednesdayCloseAt),
						thursdayCloseAt: toSafeValue(model.thursdayCloseAt),
						fridayCloseAt: toSafeValue(model.fridayCloseAt),
						saturdayCloseAt: toSafeValue(model.saturdayCloseAt),
						sundayCloseAt: toSafeValue(model.sundayCloseAt),
						availableAtHoliday: toSafeValue(model.availableAtHoliday),
						hospitalImages: toSafeValue(model.hospitalImages),
						availableMedicalSubjects: toSafeValue(model.availableMedicalSubjects),
						availableMedicalSubject: toText(model.availableMedicalSubject),
						businessLicenseFileUrl: toText(model.businessLicenseFileUrl),
						accountBankName: toText(model.accountBankName),
						accountNumber: toText(model.accountNumber),
						accountOwnerName: toText(model.accountOwnerName),
						invoiceEmail: toText(model.invoiceEmail),
						subscriptionPlan: toSafeValue(model.subscriptionPlan),
						subscriptionCardType: toText(model.subscriptionCardType),
						subscriptionCardCompany: toText(model.subscriptionCardCompany),
						subscriptionCardOwnerName: toText(model.subscriptionCardOwnerName),
						subscriptionCardNumber: toText(model.subscriptionCardNumber),
						subscriptionCardValidDate: toText(model.subscriptionCardValidDate),
						subscriptionCardCvc: toText(model.subscriptionCardCvc),
						isReservationAllTime: toSafeValue(model.isReservationAllTime),
						reservationOpenAt: toSafeValue(model.reservationOpenAt),
						reservationCloseAt: toSafeValue(model.reservationCloseAt),
						reservationOpenAt2: toSafeValue(model.reservationOpenAt2),
						reservationCloseAt2: toSafeValue(model.reservationCloseAt2),
						reservationInvervalMinutes: toNumber(model.reservationInvervalMinutes),
						reservationAvailableDoctorIds: toSafeValue(model.reservationAvailableDoctorIds),
						settlementPersonName: toText(model.settlementPersonName),
						settlementPersonPhone: toText(model.settlementPersonPhone),
						businessNumber: toText(model.businessNumber),
						mondayLunchStartAt: toSafeValue(model.mondayLunchStartAt),
						tuesdayLunchStartAt: toSafeValue(model.tuesdayLunchStartAt),
						wednesdayLunchStartAt: toSafeValue(model.wednesdayLunchStartAt),
						thursdayLunchStartAt: toSafeValue(model.thursdayLunchStartAt),
						fridayLunchStartAt: toSafeValue(model.fridayLunchStartAt),
						saturdayLunchStartAt: toSafeValue(model.saturdayLunchStartAt),
						sundayLunchStartAt: toSafeValue(model.sundayLunchStartAt),
						mondayLunchEndAt: toSafeValue(model.mondayLunchEndAt),
						tuesdayLunchEndAt: toSafeValue(model.tuesdayLunchEndAt),
						wednesdayLunchEndAt: toSafeValue(model.wednesdayLunchEndAt),
						thursdayLunchEndAt: toSafeValue(model.thursdayLunchEndAt),
						fridayLunchEndAt: toSafeValue(model.fridayLunchEndAt),
						saturdayLunchEndAt: toSafeValue(model.saturdayLunchEndAt),
						sundayLunchEndAt: toSafeValue(model.sundayLunchEndAt),
						postCode: toText(model.postCode),
						phone1: toText(model.phone1),
						phone2: toText(model.phone2),
						phone3: toText(model.phone3),
						fax1: toText(model.fax1),
						fax2: toText(model.fax2),
						fax3: toText(model.fax3),
						settlementPersonPhone1: toText(model.settlementPersonPhone1),
						settlementPersonPhone2: toText(model.settlementPersonPhone2),
						settlementPersonPhone3: toText(model.settlementPersonPhone3),
						mondayLunch: toSafeValue(model.mondayLunch),
						tuesdayLunch: toSafeValue(model.tuesdayLunch),
						wednesdayLunch: toSafeValue(model.wednesdayLunch),
						thursdayLunch: toSafeValue(model.thursdayLunch),
						fridayLunch: toSafeValue(model.fridayLunch),
						saturdayLunch: toSafeValue(model.saturdayLunch),
						sundayLunch: toSafeValue(model.sundayLunch),
						operatingTimes: toSafeValue(model.operatingTimes),
						holidayWeekDays: toSafeValue(model.holidayWeekDays),
						quit: toSafeValue(model.quit),
						whyQuit: toSafeValue(model.whyQuit),
						quitDate: toSafeValue(model.quitDate),
						planUntilDate: toSafeValue(model.planUntilDate),
						approveState: toText(model.approveState),
						hospitalState: toText(model.hospitalState),
						subscriptionStartAt: toSafeValue(model.subscriptionStartAt),
						subscriptionNumOfAdditionalPeople: toNumber(model.subscriptionNumOfAdditionalPeople),
						isAddressJibun: toSafeValue(model.isAddressJibun),
						subscriptionPayment: toSafeValue(model.subscriptionPayment),
						hospitalNumber: toText(model.hospitalNumber),
						enableAutoPay: toSafeValue(model.enableAutoPay),
						enableAutoAccept: toSafeValue(model.enableAutoAccept),
						nextClinicNotificationDeadline: toNumber(model.nextClinicNotificationDeadline),
						minMilageSubscription: toSafeValue(model.minMilageSubscription),
						autoPurchaseMileages: toNumber(model.autoPurchaseMileages),
						minMileages: toNumber(model.minMileages),
						pushMileages: toNumber(model.pushMileages),
						billingKey: toText(model.billingKey),
						cardName: toText(model.cardName),
						cardNumber: toText(model.cardNumber),
						hospitalType: toText(model.hospitalType),
						signUpCode: toText(model.signUpCode),
						signUpCodePhone: toText(model.signUpCodePhone),
						signUpCodeChannel: toText(model.signUpCodeChannel),
						whenToPay: toText(model.whenToPay),
						whenToPayBase: toText(model.whenToPayBase),
						whenToPayDay: toNumber(model.whenToPayDay),
						quotationPaymentMethods: toSafeValue(model.quotationPaymentMethods),
					};
				},
			},

			HospitalWorkModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						reservationDateTime: null,
						callStartTime: null,
						callEndTime: null,
						clinicState: null,
						patientId: '',
						patientName: '',
						patientAddress: '',
						patientPhone: '',
						patientResidentNumber: '',
						patientGender: '',
						patientBirth: null,
						medicationInUse: '',
						allergy: '',
						diseases: null,
						isSecondTimeClinic: null,
						isVideoClinic: null,
						symptomDescription: '',
						images: null,
						requestCertificates: null,
						hospitalId: '',
						hospitalName: '',
						doctorId: '',
						doctorName: '',
						patientUserMemo: '',
						waitingTimeMinutes: 0,
						doctorMemo: '',
						priceBenefit: 0,
						priceNonBenefit: 0,
						priceDelivery: 0,
						prescriptionFileUrl: '',
						prescriptionBriefDescription: '',
						isNextScheduleRequired: null,
						nextScheduleAfter: 0,
						nextScheduleDurationUnit: '',
						nextClinicDate: 0,
						nextScheduleDescription: '',
						videoRecordFileUrl: '',
						audioRecordFileUrl: '',
						payAt: null,
						pharmacyId: '',
						pharmacyWorkId: '',
						pharmacyName: '',
						isSettled: null,
						settledAt: null,
						settleAmount: 0,
						paymentAmount: 0,
						paymentResult: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						reservationDateTime: toSafeValue(model.reservationDateTime),
						callStartTime: toSafeValue(model.callStartTime),
						callEndTime: toSafeValue(model.callEndTime),
						clinicState: toSafeValue(model.clinicState),
						patientId: toText(model.patientId),
						patientName: toText(model.patientName),
						patientAddress: toText(model.patientAddress),
						patientPhone: toText(model.patientPhone),
						patientResidentNumber: toText(model.patientResidentNumber),
						patientGender: toText(model.patientGender),
						patientBirth: toSafeValue(model.patientBirth),
						medicationInUse: toText(model.medicationInUse),
						allergy: toText(model.allergy),
						diseases: toSafeValue(model.diseases),
						isSecondTimeClinic: toSafeValue(model.isSecondTimeClinic),
						isVideoClinic: toSafeValue(model.isVideoClinic),
						symptomDescription: toText(model.symptomDescription),
						images: toSafeValue(model.images),
						requestCertificates: toSafeValue(model.requestCertificates),
						hospitalId: toText(model.hospitalId),
						hospitalName: toText(model.hospitalName),
						doctorId: toText(model.doctorId),
						doctorName: toText(model.doctorName),
						patientUserMemo: toText(model.patientUserMemo),
						waitingTimeMinutes: toNumber(model.waitingTimeMinutes),
						doctorMemo: toText(model.doctorMemo),
						priceBenefit: toNumber(model.priceBenefit),
						priceNonBenefit: toNumber(model.priceNonBenefit),
						priceDelivery: toNumber(model.priceDelivery),
						prescriptionFileUrl: toText(model.prescriptionFileUrl),
						prescriptionBriefDescription: toText(model.prescriptionBriefDescription),
						isNextScheduleRequired: toSafeValue(model.isNextScheduleRequired),
						nextScheduleAfter: toNumber(model.nextScheduleAfter),
						nextScheduleDurationUnit: toText(model.nextScheduleDurationUnit),
						nextClinicDate: toNumber(model.nextClinicDate),
						nextScheduleDescription: toText(model.nextScheduleDescription),
						videoRecordFileUrl: toText(model.videoRecordFileUrl),
						audioRecordFileUrl: toText(model.audioRecordFileUrl),
						payAt: toSafeValue(model.payAt),
						pharmacyId: toText(model.pharmacyId),
						pharmacyWorkId: toText(model.pharmacyWorkId),
						pharmacyName: toText(model.pharmacyName),
						isSettled: toSafeValue(model.isSettled),
						settledAt: toSafeValue(model.settledAt),
						settleAmount: toNumber(model.settleAmount),
						paymentAmount: toNumber(model.paymentAmount),
						paymentResult: toSafeValue(model.paymentResult),
					};
				},
			},

			PharmacyModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						accountId: '',
						accountUserName: '',
						accountUserBirth: null,
						accountUserPhone: '',
						accountUserEmail: '',
						pharmacistLicenseImageUrl: '',
						numOfFavorites: 0,
						name: '',
						address: '',
						addressDetail: '',
						gpsX: 0,
						gpsY: 0,
						phone: '',
						fax: '',
						mondayEnable: null,
						tuesdayEnable: null,
						wednesdayEnable: null,
						thursdayEnable: null,
						fridayEnable: null,
						saturdayEnable: null,
						sundayEnable: null,
						mondayOpenAt: null,
						tuesdayOpenAt: null,
						wednesdayOpenAt: null,
						thursdayOpenAt: null,
						fridayOpenAt: null,
						saturdayOpenAt: null,
						sundayOpenAt: null,
						mondayCloseAt: null,
						tuesdayCloseAt: null,
						wednesdayCloseAt: null,
						thursdayCloseAt: null,
						fridayCloseAt: null,
						saturdayCloseAt: null,
						sundayCloseAt: null,
						availableAtHoliday: null,
						pharmacyImages: null,
						businessLicenseFileUrl: '',
						accountBankName: '',
						accountNumber: '',
						accountOwnerName: '',
						invoiceEmail: '',
						tagList: null,
						businessNumber: '',
						settlementPersonName: '',
						settlementPersonPhone: '',
						mondayLunchStartAt: null,
						tuesdayLunchStartAt: null,
						wednesdayLunchStartAt: null,
						thursdayLunchStartAt: null,
						fridayLunchStartAt: null,
						saturdayLunchStartAt: null,
						sundayLunchStartAt: null,
						mondayLunchEndAt: null,
						tuesdayLunchEndAt: null,
						wednesdayLunchEndAt: null,
						thursdayLunchEndAt: null,
						fridayLunchEndAt: null,
						saturdayLunchEndAt: null,
						sundayLunchEndAt: null,
						operatingTimes: null,
						mondayLunch: null,
						tuesdayLunch: null,
						wednesdayLunch: null,
						thursdayLunch: null,
						fridayLunch: null,
						saturdayLunch: null,
						sundayLunch: null,
						postCode: '',
						holidayWeekDays: null,
						phone1: '',
						phone2: '',
						phone3: '',
						fax1: '',
						fax2: '',
						fax3: '',
						settlementPersonPhone1: '',
						settlementPersonPhone2: '',
						settlementPersonPhone3: '',
						accountUserPhone1: '',
						accountUserPhone2: '',
						accountUserPhone3: '',
						quit: null,
						whyQuit: null,
						approveState: '',
						pharmacyState: '',
						kakaoTalkIdNotification: null,
						kakaoTalkId: '',
						shopOpened: null,
						isAddressJibun: null,
						gender: '',
						pharmacyNumber: '',
						enableInDay: null,
						enableQuick: null,
						enableMail: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						accountId: toText(model.accountId),
						accountUserName: toText(model.accountUserName),
						accountUserBirth: toSafeValue(model.accountUserBirth),
						accountUserPhone: toText(model.accountUserPhone),
						accountUserEmail: toText(model.accountUserEmail),
						pharmacistLicenseImageUrl: toText(model.pharmacistLicenseImageUrl),
						numOfFavorites: toNumber(model.numOfFavorites),
						name: toText(model.name),
						address: toText(model.address),
						addressDetail: toText(model.addressDetail),
						gpsX: toNumber(model.gpsX),
						gpsY: toNumber(model.gpsY),
						phone: toText(model.phone),
						fax: toText(model.fax),
						mondayEnable: toSafeValue(model.mondayEnable),
						tuesdayEnable: toSafeValue(model.tuesdayEnable),
						wednesdayEnable: toSafeValue(model.wednesdayEnable),
						thursdayEnable: toSafeValue(model.thursdayEnable),
						fridayEnable: toSafeValue(model.fridayEnable),
						saturdayEnable: toSafeValue(model.saturdayEnable),
						sundayEnable: toSafeValue(model.sundayEnable),
						mondayOpenAt: toSafeValue(model.mondayOpenAt),
						tuesdayOpenAt: toSafeValue(model.tuesdayOpenAt),
						wednesdayOpenAt: toSafeValue(model.wednesdayOpenAt),
						thursdayOpenAt: toSafeValue(model.thursdayOpenAt),
						fridayOpenAt: toSafeValue(model.fridayOpenAt),
						saturdayOpenAt: toSafeValue(model.saturdayOpenAt),
						sundayOpenAt: toSafeValue(model.sundayOpenAt),
						mondayCloseAt: toSafeValue(model.mondayCloseAt),
						tuesdayCloseAt: toSafeValue(model.tuesdayCloseAt),
						wednesdayCloseAt: toSafeValue(model.wednesdayCloseAt),
						thursdayCloseAt: toSafeValue(model.thursdayCloseAt),
						fridayCloseAt: toSafeValue(model.fridayCloseAt),
						saturdayCloseAt: toSafeValue(model.saturdayCloseAt),
						sundayCloseAt: toSafeValue(model.sundayCloseAt),
						availableAtHoliday: toSafeValue(model.availableAtHoliday),
						pharmacyImages: toSafeValue(model.pharmacyImages),
						businessLicenseFileUrl: toText(model.businessLicenseFileUrl),
						accountBankName: toText(model.accountBankName),
						accountNumber: toText(model.accountNumber),
						accountOwnerName: toText(model.accountOwnerName),
						invoiceEmail: toText(model.invoiceEmail),
						tagList: toSafeValue(model.tagList),
						businessNumber: toText(model.businessNumber),
						settlementPersonName: toText(model.settlementPersonName),
						settlementPersonPhone: toText(model.settlementPersonPhone),
						mondayLunchStartAt: toSafeValue(model.mondayLunchStartAt),
						tuesdayLunchStartAt: toSafeValue(model.tuesdayLunchStartAt),
						wednesdayLunchStartAt: toSafeValue(model.wednesdayLunchStartAt),
						thursdayLunchStartAt: toSafeValue(model.thursdayLunchStartAt),
						fridayLunchStartAt: toSafeValue(model.fridayLunchStartAt),
						saturdayLunchStartAt: toSafeValue(model.saturdayLunchStartAt),
						sundayLunchStartAt: toSafeValue(model.sundayLunchStartAt),
						mondayLunchEndAt: toSafeValue(model.mondayLunchEndAt),
						tuesdayLunchEndAt: toSafeValue(model.tuesdayLunchEndAt),
						wednesdayLunchEndAt: toSafeValue(model.wednesdayLunchEndAt),
						thursdayLunchEndAt: toSafeValue(model.thursdayLunchEndAt),
						fridayLunchEndAt: toSafeValue(model.fridayLunchEndAt),
						saturdayLunchEndAt: toSafeValue(model.saturdayLunchEndAt),
						sundayLunchEndAt: toSafeValue(model.sundayLunchEndAt),
						operatingTimes: toSafeValue(model.operatingTimes),
						mondayLunch: toSafeValue(model.mondayLunch),
						tuesdayLunch: toSafeValue(model.tuesdayLunch),
						wednesdayLunch: toSafeValue(model.wednesdayLunch),
						thursdayLunch: toSafeValue(model.thursdayLunch),
						fridayLunch: toSafeValue(model.fridayLunch),
						saturdayLunch: toSafeValue(model.saturdayLunch),
						sundayLunch: toSafeValue(model.sundayLunch),
						postCode: toText(model.postCode),
						holidayWeekDays: toSafeValue(model.holidayWeekDays),
						phone1: toText(model.phone1),
						phone2: toText(model.phone2),
						phone3: toText(model.phone3),
						fax1: toText(model.fax1),
						fax2: toText(model.fax2),
						fax3: toText(model.fax3),
						settlementPersonPhone1: toText(model.settlementPersonPhone1),
						settlementPersonPhone2: toText(model.settlementPersonPhone2),
						settlementPersonPhone3: toText(model.settlementPersonPhone3),
						accountUserPhone1: toText(model.accountUserPhone1),
						accountUserPhone2: toText(model.accountUserPhone2),
						accountUserPhone3: toText(model.accountUserPhone3),
						quit: toSafeValue(model.quit),
						whyQuit: toSafeValue(model.whyQuit),
						approveState: toText(model.approveState),
						pharmacyState: toText(model.pharmacyState),
						kakaoTalkIdNotification: toSafeValue(model.kakaoTalkIdNotification),
						kakaoTalkId: toText(model.kakaoTalkId),
						shopOpened: toSafeValue(model.shopOpened),
						isAddressJibun: toSafeValue(model.isAddressJibun),
						gender: toText(model.gender),
						pharmacyNumber: toText(model.pharmacyNumber),
						enableInDay: toSafeValue(model.enableInDay),
						enableQuick: toSafeValue(model.enableQuick),
						enableMail: toSafeValue(model.enableMail),
					};
				},
			},

			PharmacyWorkModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						guideSaved: null,
						priceSaved: null,
						whyCancel: '',
						deliveryState: null,
						deliveryType: null,
						address: '',
						addressDetail: '',
						addressAdditionalInfo: '',
						requestPharmacyProducts: null,
						patientId: '',
						patientName: '',
						patientGender: '',
						patientBirth: null,
						patientPhone: '',
						prescriptionFileUrl: '',
						paymentAmount: 0,
						settleAmount: 0,
						payAt: null,
						receiptImageUrl: '',
						hospitalId: '',
						hospitalWorkId: '',
						hospitalName: '',
						doctorId: '',
						doctorName: '',
						pharmacyId: '',
						pharmacyName: '',
						pharmacyWorkerName: '',
						pharmacyAddress: '',
						patientUserMemo: '',
						name: '',
						guide: '',
						morning: null,
						lunch: null,
						dinner: null,
						night: null,
						takeMedicineTime: null,
						takeMedicineTimeAfterOrBefore: 0,
						timesPerDay: 0,
						numOfdays: 0,
						priceMedicine: 0,
						priceEtc: 0,
						priceDelivery: 0,
						makingMedicineAt: null,
						pharmacyMemo: '',
						trackingNumber: '',
						deliveryPersonPhone: '',
						isSettled: null,
						settledAt: null,
						pharmacistId: '',
						deliveryCancel: null,
						deliveryCancelReason: '',
						deliveryCancelType: '',
						paymentResult: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						guideSaved: toSafeValue(model.guideSaved),
						priceSaved: toSafeValue(model.priceSaved),
						whyCancel: toText(model.whyCancel),
						deliveryState: toSafeValue(model.deliveryState),
						deliveryType: toSafeValue(model.deliveryType),
						address: toText(model.address),
						addressDetail: toText(model.addressDetail),
						addressAdditionalInfo: toText(model.addressAdditionalInfo),
						requestPharmacyProducts: toSafeValue(model.requestPharmacyProducts),
						patientId: toText(model.patientId),
						patientName: toText(model.patientName),
						patientGender: toText(model.patientGender),
						patientBirth: toSafeValue(model.patientBirth),
						patientPhone: toText(model.patientPhone),
						prescriptionFileUrl: toText(model.prescriptionFileUrl),
						paymentAmount: toNumber(model.paymentAmount),
						settleAmount: toNumber(model.settleAmount),
						payAt: toSafeValue(model.payAt),
						receiptImageUrl: toText(model.receiptImageUrl),
						hospitalId: toText(model.hospitalId),
						hospitalWorkId: toText(model.hospitalWorkId),
						hospitalName: toText(model.hospitalName),
						doctorId: toText(model.doctorId),
						doctorName: toText(model.doctorName),
						pharmacyId: toText(model.pharmacyId),
						pharmacyName: toText(model.pharmacyName),
						pharmacyWorkerName: toText(model.pharmacyWorkerName),
						pharmacyAddress: toText(model.pharmacyAddress),
						patientUserMemo: toText(model.patientUserMemo),
						name: toText(model.name),
						guide: toText(model.guide),
						morning: toSafeValue(model.morning),
						lunch: toSafeValue(model.lunch),
						dinner: toSafeValue(model.dinner),
						night: toSafeValue(model.night),
						takeMedicineTime: toSafeValue(model.takeMedicineTime),
						takeMedicineTimeAfterOrBefore: toNumber(model.takeMedicineTimeAfterOrBefore),
						timesPerDay: toNumber(model.timesPerDay),
						numOfdays: toNumber(model.numOfdays),
						priceMedicine: toNumber(model.priceMedicine),
						priceEtc: toNumber(model.priceEtc),
						priceDelivery: toNumber(model.priceDelivery),
						makingMedicineAt: toSafeValue(model.makingMedicineAt),
						pharmacyMemo: toText(model.pharmacyMemo),
						trackingNumber: toText(model.trackingNumber),
						deliveryPersonPhone: toText(model.deliveryPersonPhone),
						isSettled: toSafeValue(model.isSettled),
						settledAt: toSafeValue(model.settledAt),
						pharmacistId: toText(model.pharmacistId),
						deliveryCancel: toSafeValue(model.deliveryCancel),
						deliveryCancelReason: toText(model.deliveryCancelReason),
						deliveryCancelType: toText(model.deliveryCancelType),
						paymentResult: toSafeValue(model.paymentResult),
					};
				},
			},

			PlanFeatureModel: {
				create: function () {
					return {
						title: '',
						text: '',
						description: '',
					};
				},
				set: function (model) {
					return {
						title: toText(model.title),
						text: toText(model.text),
						description: toText(model.description),
					};
				},
			},

			UserModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						passwordChangeAt: null,
						recentLoginVersion: '',
						recentLoginAt: null,
						email: '',
						name: '',
						residentNumber: '',
						phone: '',
						birth: null,
						gender: '',
						recommendHospitalCode: '',
						recommendHospitalId: '',
						medicationInUse: '',
						address: '',
						doroAddress: '',
						addressDetail: '',
						addressPostCode: '',
						allergy: '',
						diseases: null,
						profileImageUrl: '',
						profileImageBlurHash: '',
						userType: null,
						membershipType: null,
						signUpMethod: null,
						point: 0,
						myInvitationLink: '',
						allowMarketing: null,
						allowServicePush: null,
						pushToken: '',
						currentHospitalId: '',
						favoriteHospitals: null,
						favoritePharmacys: null,
						hasSeenNotificationTime: null,
						recentPharmacyIds: null,
						myMainCardId: '',
						myMainCardBillingKey: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						passwordChangeAt: toSafeValue(model.passwordChangeAt),
						recentLoginVersion: toText(model.recentLoginVersion),
						recentLoginAt: toSafeValue(model.recentLoginAt),
						email: toText(model.email),
						name: toText(model.name),
						residentNumber: toText(model.residentNumber),
						phone: toText(model.phone),
						birth: toSafeValue(model.birth),
						gender: toText(model.gender),
						recommendHospitalCode: toText(model.recommendHospitalCode),
						recommendHospitalId: toText(model.recommendHospitalId),
						medicationInUse: toText(model.medicationInUse),
						address: toText(model.address),
						doroAddress: toText(model.doroAddress),
						addressDetail: toText(model.addressDetail),
						addressPostCode: toText(model.addressPostCode),
						allergy: toText(model.allergy),
						diseases: toSafeValue(model.diseases),
						profileImageUrl: toText(model.profileImageUrl),
						profileImageBlurHash: toText(model.profileImageBlurHash),
						userType: toSafeValue(model.userType),
						membershipType: toSafeValue(model.membershipType),
						signUpMethod: toSafeValue(model.signUpMethod),
						point: toNumber(model.point),
						myInvitationLink: toText(model.myInvitationLink),
						allowMarketing: toSafeValue(model.allowMarketing),
						allowServicePush: toSafeValue(model.allowServicePush),
						pushToken: toText(model.pushToken),
						currentHospitalId: toText(model.currentHospitalId),
						favoriteHospitals: toSafeValue(model.favoriteHospitals),
						favoritePharmacys: toSafeValue(model.favoritePharmacys),
						hasSeenNotificationTime: toSafeValue(model.hasSeenNotificationTime),
						recentPharmacyIds: toSafeValue(model.recentPharmacyIds),
						myMainCardId: toText(model.myMainCardId),
						myMainCardBillingKey: toText(model.myMainCardBillingKey),
					};
				},
			},

			CouponModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						type: '',
						duration: 0,
						number: '',
						count: 0,
						validity: 0,
						expirationFrom: null,
						expirationTo: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						type: toText(model.type),
						duration: toNumber(model.duration),
						number: toText(model.number),
						count: toNumber(model.count),
						validity: toNumber(model.validity),
						expirationFrom: toSafeValue(model.expirationFrom),
						expirationTo: toSafeValue(model.expirationTo),
					};
				},
			},

			CouponUsageModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						userType: '',
						userId: '',
						userName: '',
						startAt: null,
						endAt: null,
						couponId: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						userType: toText(model.userType),
						userId: toText(model.userId),
						userName: toText(model.userName),
						startAt: toSafeValue(model.startAt),
						endAt: toSafeValue(model.endAt),
						couponId: toText(model.couponId),
					};
				},
			},

			HospitalSubscriptionPlanModel: {
				create: function () {
					return {
						id: '',
						title: '',
						price: 0,
						features: null,
						paymentDurationMonth: 0,
						clinicTimeLimit: 0,
						clinicTimeUnlimited: null,
						pushLimit: 0,
						pushLimitUnlimited: null,
						storage: 0,
						storageUnlimited: null,
						index: 0,
						benefit: '',
						description: '',
						feeRate: 0,
						active: null,
						trialDurationMonth: 0,
						pricePerItem: 0,
						noPricePerItem: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						title: toText(model.title),
						price: toNumber(model.price),
						features: toSafeValue(model.features),
						paymentDurationMonth: toNumber(model.paymentDurationMonth),
						clinicTimeLimit: toNumber(model.clinicTimeLimit),
						clinicTimeUnlimited: toSafeValue(model.clinicTimeUnlimited),
						pushLimit: toNumber(model.pushLimit),
						pushLimitUnlimited: toSafeValue(model.pushLimitUnlimited),
						storage: toNumber(model.storage),
						storageUnlimited: toSafeValue(model.storageUnlimited),
						index: toNumber(model.index),
						benefit: toText(model.benefit),
						description: toText(model.description),
						feeRate: toNumber(model.feeRate),
						active: toSafeValue(model.active),
						trialDurationMonth: toNumber(model.trialDurationMonth),
						pricePerItem: toNumber(model.pricePerItem),
						noPricePerItem: toSafeValue(model.noPricePerItem),
					};
				},
			},

			PopupModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						name: '',
						linkTarget: '',
						imageUrl: '',
						link: '',
						showStartDateTime: null,
						showEndDateTime: null,
						positionTop: 0,
						positionLeft: 0,
						width: 0,
						height: 0,
						active: null,
						sites: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						name: toText(model.name),
						linkTarget: toText(model.linkTarget),
						imageUrl: toText(model.imageUrl),
						link: toText(model.link),
						showStartDateTime: toSafeValue(model.showStartDateTime),
						showEndDateTime: toSafeValue(model.showEndDateTime),
						positionTop: toNumber(model.positionTop),
						positionLeft: toNumber(model.positionLeft),
						width: toNumber(model.width),
						height: toNumber(model.height),
						active: toSafeValue(model.active),
						sites: toSafeValue(model.sites),
					};
				},
			},

			AdminNotificationModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						reservedAt: null,
						sended: null,
						sendedAt: null,
						title: '',
						content: '',
						link: '',
						target: null,
						receivedAppUserIds: null,
						receivedHospitalUserIds: null,
						receivedPharmacyUserIds: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						reservedAt: toSafeValue(model.reservedAt),
						sended: toSafeValue(model.sended),
						sendedAt: toSafeValue(model.sendedAt),
						title: toText(model.title),
						content: toText(model.content),
						link: toText(model.link),
						target: toSafeValue(model.target),
						receivedAppUserIds: toSafeValue(model.receivedAppUserIds),
						receivedHospitalUserIds: toSafeValue(model.receivedHospitalUserIds),
						receivedPharmacyUserIds: toSafeValue(model.receivedPharmacyUserIds),
					};
				},
			},

			HospitalWorkerModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						name: '',
						accountId: '',
						hospitalWorkerType: null,
						title: '',
						enable: null,
						hospitalId: '',
						hospitalName: '',
						specialistLicenseFileUrls: null,
						doctorLicenseImageUrl: '',
						mondayEnable: null,
						tuesdayEnable: null,
						wednesdayEnable: null,
						thursdayEnable: null,
						fridayEnable: null,
						saturdayEnable: null,
						sundayEnable: null,
						hasLunchTime: null,
						lunchStartTime: null,
						lunchcEndTime: null,
						mondayOpenAt: null,
						tuesdayOpenAt: null,
						wednesdayOpenAt: null,
						thursdayOpenAt: null,
						fridayOpenAt: null,
						saturdayOpenAt: null,
						sundayOpenAt: null,
						mondayCloseAt: null,
						tuesdayCloseAt: null,
						wednesdayCloseAt: null,
						thursdayCloseAt: null,
						fridayCloseAt: null,
						saturdayCloseAt: null,
						sundayCloseAt: null,
						isSameWithHospitalHoliday: null,
						faceImageUrl: '',
						tagList: null,
						isMale: null,
						birth: '',
						phone: '',
						phone1: '',
						phone2: '',
						phone3: '',
						email: '',
						job: '',
						team: '',
						operatingTimes: null,
						mondayLunch: null,
						tuesdayLunch: null,
						wednesdayLunch: null,
						thursdayLunch: null,
						fridayLunch: null,
						saturdayLunch: null,
						sundayLunch: null,
						holidayWeekDays: null,
						main: null,
						memo: '',
						hospitalSubscriptionId: '',
						step: 0,
						reminderInvervalMinutes: 0,
						availableAtHoliday: null,
						availableNormalClinic: null,
						availableOnlineClinic: null,
						availableVideoCall: null,
						availableAudioCall: null,
						reminderLimit: 0,
						reminderAllTime: null,
						reminderStartTime: null,
						reminderEndTime: null,
						gender: '',
						showAllClinic: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						name: toText(model.name),
						accountId: toText(model.accountId),
						hospitalWorkerType: toSafeValue(model.hospitalWorkerType),
						title: toText(model.title),
						enable: toSafeValue(model.enable),
						hospitalId: toText(model.hospitalId),
						hospitalName: toText(model.hospitalName),
						specialistLicenseFileUrls: toSafeValue(model.specialistLicenseFileUrls),
						doctorLicenseImageUrl: toText(model.doctorLicenseImageUrl),
						mondayEnable: toSafeValue(model.mondayEnable),
						tuesdayEnable: toSafeValue(model.tuesdayEnable),
						wednesdayEnable: toSafeValue(model.wednesdayEnable),
						thursdayEnable: toSafeValue(model.thursdayEnable),
						fridayEnable: toSafeValue(model.fridayEnable),
						saturdayEnable: toSafeValue(model.saturdayEnable),
						sundayEnable: toSafeValue(model.sundayEnable),
						hasLunchTime: toSafeValue(model.hasLunchTime),
						lunchStartTime: toSafeValue(model.lunchStartTime),
						lunchcEndTime: toSafeValue(model.lunchcEndTime),
						mondayOpenAt: toSafeValue(model.mondayOpenAt),
						tuesdayOpenAt: toSafeValue(model.tuesdayOpenAt),
						wednesdayOpenAt: toSafeValue(model.wednesdayOpenAt),
						thursdayOpenAt: toSafeValue(model.thursdayOpenAt),
						fridayOpenAt: toSafeValue(model.fridayOpenAt),
						saturdayOpenAt: toSafeValue(model.saturdayOpenAt),
						sundayOpenAt: toSafeValue(model.sundayOpenAt),
						mondayCloseAt: toSafeValue(model.mondayCloseAt),
						tuesdayCloseAt: toSafeValue(model.tuesdayCloseAt),
						wednesdayCloseAt: toSafeValue(model.wednesdayCloseAt),
						thursdayCloseAt: toSafeValue(model.thursdayCloseAt),
						fridayCloseAt: toSafeValue(model.fridayCloseAt),
						saturdayCloseAt: toSafeValue(model.saturdayCloseAt),
						sundayCloseAt: toSafeValue(model.sundayCloseAt),
						isSameWithHospitalHoliday: toSafeValue(model.isSameWithHospitalHoliday),
						faceImageUrl: toText(model.faceImageUrl),
						tagList: toSafeValue(model.tagList),
						isMale: toSafeValue(model.isMale),
						birth: toText(model.birth),
						phone: toText(model.phone),
						phone1: toText(model.phone1),
						phone2: toText(model.phone2),
						phone3: toText(model.phone3),
						email: toText(model.email),
						job: toText(model.job),
						team: toText(model.team),
						operatingTimes: toSafeValue(model.operatingTimes),
						mondayLunch: toSafeValue(model.mondayLunch),
						tuesdayLunch: toSafeValue(model.tuesdayLunch),
						wednesdayLunch: toSafeValue(model.wednesdayLunch),
						thursdayLunch: toSafeValue(model.thursdayLunch),
						fridayLunch: toSafeValue(model.fridayLunch),
						saturdayLunch: toSafeValue(model.saturdayLunch),
						sundayLunch: toSafeValue(model.sundayLunch),
						holidayWeekDays: toSafeValue(model.holidayWeekDays),
						main: toSafeValue(model.main),
						memo: toText(model.memo),
						hospitalSubscriptionId: toText(model.hospitalSubscriptionId),
						step: toNumber(model.step),
						reminderInvervalMinutes: toNumber(model.reminderInvervalMinutes),
						availableAtHoliday: toSafeValue(model.availableAtHoliday),
						availableNormalClinic: toSafeValue(model.availableNormalClinic),
						availableOnlineClinic: toSafeValue(model.availableOnlineClinic),
						availableVideoCall: toSafeValue(model.availableVideoCall),
						availableAudioCall: toSafeValue(model.availableAudioCall),
						reminderLimit: toNumber(model.reminderLimit),
						reminderAllTime: toSafeValue(model.reminderAllTime),
						reminderStartTime: toSafeValue(model.reminderStartTime),
						reminderEndTime: toSafeValue(model.reminderEndTime),
						gender: toText(model.gender),
						showAllClinic: toSafeValue(model.showAllClinic),
					};
				},
			},

			PharmacistModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						name: '',
						pharmacistLicenseImageUrl: '',
						profileImageUrl: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						name: toText(model.name),
						pharmacistLicenseImageUrl: toText(model.pharmacistLicenseImageUrl),
						profileImageUrl: toText(model.profileImageUrl),
					};
				},
			},

			NonBenefitModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						type: '',
						name: '',
						price: 0,
						isAttached: null,
						fileUrl: '',
						orderPriority: 0,
						descriptionFileUrlList: null,
						descriptionImageUrlList: null,
						descriptionText: '',
						hide: null,
						doctorIds: null,
						selections: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						type: toText(model.type),
						name: toText(model.name),
						price: toNumber(model.price),
						isAttached: toSafeValue(model.isAttached),
						fileUrl: toText(model.fileUrl),
						orderPriority: toNumber(model.orderPriority),
						descriptionFileUrlList: toSafeValue(model.descriptionFileUrlList),
						descriptionImageUrlList: toSafeValue(model.descriptionImageUrlList),
						descriptionText: toText(model.descriptionText),
						hide: toSafeValue(model.hide),
						doctorIds: toSafeValue(model.doctorIds),
						selections: toSafeValue(model.selections),
					};
				},
			},

			HospitalNoticeModel: {
				create: function () {
					return {
						id: '',
						hospitalId: '',
						authorId: '',
						authorName: '',
						authorTitle: '',
						createAt: null,
						header: '',
						isMainNotice: null,
						title: '',
						content: '',
						attachmentUrls: null,
						imageUrls: null,
						docUrls: null,
						movieUrls: null,
						youtubeUrls: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						hospitalId: toText(model.hospitalId),
						authorId: toText(model.authorId),
						authorName: toText(model.authorName),
						authorTitle: toText(model.authorTitle),
						createAt: toSafeValue(model.createAt),
						header: toText(model.header),
						isMainNotice: toSafeValue(model.isMainNotice),
						title: toText(model.title),
						content: toText(model.content),
						attachmentUrls: toSafeValue(model.attachmentUrls),
						imageUrls: toSafeValue(model.imageUrls),
						docUrls: toSafeValue(model.docUrls),
						movieUrls: toSafeValue(model.movieUrls),
						youtubeUrls: toSafeValue(model.youtubeUrls),
					};
				},
			},

			TempHolidayModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						hospitalId: '',
						pharmacyId: '',
						date: null,
						name: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						hospitalId: toText(model.hospitalId),
						pharmacyId: toText(model.pharmacyId),
						date: toSafeValue(model.date),
						name: toText(model.name),
					};
				},
			},

			RelatedPharmacyModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						pharmacyId: '',
						name: '',
						phone: '',
						address: '',
						addressDetail: '',
						order: 0,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						pharmacyId: toText(model.pharmacyId),
						name: toText(model.name),
						phone: toText(model.phone),
						address: toText(model.address),
						addressDetail: toText(model.addressDetail),
						order: toNumber(model.order),
					};
				},
			},

			DocumentModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						title: '',
						fileUrls: null,
						hospitalWorkId: '',
						hospitalName: '',
						documentType: null,
						download: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						title: toText(model.title),
						fileUrls: toSafeValue(model.fileUrls),
						hospitalWorkId: toText(model.hospitalWorkId),
						hospitalName: toText(model.hospitalName),
						documentType: toSafeValue(model.documentType),
						download: toSafeValue(model.download),
					};
				},
			},

			ManualModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						type: '',
						title: '',
						content: '',
						imageUrls: null,
						docUrls: null,
						movieUrls: null,
						youtubeUrls: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						type: toText(model.type),
						title: toText(model.title),
						content: toText(model.content),
						imageUrls: toSafeValue(model.imageUrls),
						docUrls: toSafeValue(model.docUrls),
						movieUrls: toSafeValue(model.movieUrls),
						youtubeUrls: toSafeValue(model.youtubeUrls),
					};
				},
			},

			NonBenefitOptionModel: {
				create: function () {
					return {
						id: '',
						name: '',
						type: '',
						price: 0,
						descriptionText: '',
						hospitalId: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						name: toText(model.name),
						type: toText(model.type),
						price: toNumber(model.price),
						descriptionText: toText(model.descriptionText),
						hospitalId: toText(model.hospitalId),
					};
				},
			},

			NonBenefitSelectionModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						optionIds: null,
						options: null,
						requiredCount: 0,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						optionIds: toSafeValue(model.optionIds),
						options: toSafeValue(model.options),
						requiredCount: toNumber(model.requiredCount),
					};
				},
			},

			ReservationCloseModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						hospitalId: '',
						doctorId: '',
						time: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						hospitalId: toText(model.hospitalId),
						doctorId: toText(model.doctorId),
						time: toSafeValue(model.time),
					};
				},
			},

			SellerUserModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						name: '',
						phone: '',
						address: '',
						addressDetail: '',
						businessNumber: '',
						ownerName: '',
						ownerPhone: '',
						ownerEmail: '',
						sellerOwnerName: '',
						sellerOwnerPhone: '',
						businessCertificateUrl: '',
						taxEmail: '',
						categorys: null,
						active: null,
						accountType: '',
						bankName: '',
						accountNumber: '',
						settleBankName: '',
						settleAccountNumber: '',
						settleAccountUser: '',
						whenToPay: '',
						manualPayment: null,
						postCode: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						name: toText(model.name),
						phone: toText(model.phone),
						address: toText(model.address),
						addressDetail: toText(model.addressDetail),
						businessNumber: toText(model.businessNumber),
						ownerName: toText(model.ownerName),
						ownerPhone: toText(model.ownerPhone),
						ownerEmail: toText(model.ownerEmail),
						sellerOwnerName: toText(model.sellerOwnerName),
						sellerOwnerPhone: toText(model.sellerOwnerPhone),
						businessCertificateUrl: toText(model.businessCertificateUrl),
						taxEmail: toText(model.taxEmail),
						categorys: toSafeValue(model.categorys),
						active: toSafeValue(model.active),
						accountType: toText(model.accountType),
						bankName: toText(model.bankName),
						accountNumber: toText(model.accountNumber),
						settleBankName: toText(model.settleBankName),
						settleAccountNumber: toText(model.settleAccountNumber),
						settleAccountUser: toText(model.settleAccountUser),
						whenToPay: toText(model.whenToPay),
						manualPayment: toSafeValue(model.manualPayment),
						postCode: toText(model.postCode),
					};
				},
			},

			SignUpCodeUserModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						name: '',
						phone: '',
						bankName: '',
						accountNumber: '',
						accountOwner: '',
						tax: null,
						taxResidentNumber: '',
						taxCompany: '',
						taxBusinessNumber: '',
						taxOwner: '',
						taxUptae: '',
						taxJongmok: '',
						taxAddress: '',
						taxEmail: '',
						taxManager: '',
						taxPhone: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						name: toText(model.name),
						phone: toText(model.phone),
						bankName: toText(model.bankName),
						accountNumber: toText(model.accountNumber),
						accountOwner: toText(model.accountOwner),
						tax: toSafeValue(model.tax),
						taxResidentNumber: toText(model.taxResidentNumber),
						taxCompany: toText(model.taxCompany),
						taxBusinessNumber: toText(model.taxBusinessNumber),
						taxOwner: toText(model.taxOwner),
						taxUptae: toText(model.taxUptae),
						taxJongmok: toText(model.taxJongmok),
						taxAddress: toText(model.taxAddress),
						taxEmail: toText(model.taxEmail),
						taxManager: toText(model.taxManager),
						taxPhone: toText(model.taxPhone),
					};
				},
			},

			UserPointUsageModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						userId: '',
						userName: '',
						hospitalId: '',
						hospitalName: '',
						amount: 0,
						usageType: '',
						hospitalWorkerId: '',
						hospitalWorkerName: '',
						paymentMethods: null,
						paymentId: '',
						paymentAmount: 0,
						serviceAmount: 0,
						resultAmount: 0,
						description: '',
						memo: '',
						hospitalWorkId: '',
						refunded: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						userId: toText(model.userId),
						userName: toText(model.userName),
						hospitalId: toText(model.hospitalId),
						hospitalName: toText(model.hospitalName),
						amount: toNumber(model.amount),
						usageType: toText(model.usageType),
						hospitalWorkerId: toText(model.hospitalWorkerId),
						hospitalWorkerName: toText(model.hospitalWorkerName),
						paymentMethods: toSafeValue(model.paymentMethods),
						paymentId: toText(model.paymentId),
						paymentAmount: toNumber(model.paymentAmount),
						serviceAmount: toNumber(model.serviceAmount),
						resultAmount: toNumber(model.resultAmount),
						description: toText(model.description),
						memo: toText(model.memo),
						hospitalWorkId: toText(model.hospitalWorkId),
						refunded: toSafeValue(model.refunded),
					};
				},
			},

			UserPointModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						userId: '',
						hospitalId: '',
						amount: 0,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						userId: toText(model.userId),
						hospitalId: toText(model.hospitalId),
						amount: toNumber(model.amount),
					};
				},
			},

			SignUpCodeChannelModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						name: '',
						viewCount: 0,
						signUpCodeUserId: '',
						url: '',
						qrUrl: '',
						signUpCode: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						name: toText(model.name),
						viewCount: toNumber(model.viewCount),
						signUpCodeUserId: toText(model.signUpCodeUserId),
						url: toText(model.url),
						qrUrl: toText(model.qrUrl),
						signUpCode: toText(model.signUpCode),
					};
				},
			},

			PartnerPointUsageModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						usageType: '',
						partnerId: '',
						partnerName: '',
						hospitalId: '',
						hospitalName: '',
						description: '',
						months: null,
						memo: '',
						amount: 0,
						transferDate: null,
						tax: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						usageType: toText(model.usageType),
						partnerId: toText(model.partnerId),
						partnerName: toText(model.partnerName),
						hospitalId: toText(model.hospitalId),
						hospitalName: toText(model.hospitalName),
						description: toText(model.description),
						months: toSafeValue(model.months),
						memo: toText(model.memo),
						amount: toNumber(model.amount),
						transferDate: toSafeValue(model.transferDate),
						tax: toSafeValue(model.tax),
					};
				},
			},
		};
	}
};

Vue.use(models);
